/* eslint-disable */
import React, { Component } from 'react';
//import modalImage from "./assets/images/modal_2.png";
import modalImage from '../../assets/images/modal_3.png';
import playImg from "../../assets/images/play button.svg";
import { withTranslation  } from "react-i18next";
import close from "../../assets/images/x.svg";

class ModalThanks extends Component  {
    message_vertical='bannerThank_3_OT'
    message_thanks = 'bannerThank_6'
    constructor(props) {
        super(props);
        console.log(props)
        this.closeModal = this.props.closeModalThanks.bind(this);
        this.openVideo  =this.props.openVideo.bind(this)

        if (this.props.vertical==='OT'){
            this.message_vertical='bannerThank_3_OT'
        }else if (this.props.vertical==='SO'){
            this.message_vertical='bannerThank_3_SO'
            this.message_thanks='bannerThank_6_so'
        }else if(this.props.vertical==='CLOUD'){
            this.message_vertical='bannerThank_3_Cloud'
            this.message_thanks='bannerThank_6_cloud'
        }else if (this.props.vertical==='AR'){
            this.message_vertical='bannerThank_3_AR'
            this.message_thanks='bannerThank_6_ar'
        }
       
      }
   
    render() {
    return (
        <div
            className="modal fade"
            id="modalThanks"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                    <div className="modal-body paddingImage">
                        <div className="container-fluid paddingImage">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 order-lg-last">
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            position: "relative",
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                                background:
                                                    "linear-gradient(0deg, #D9D9D9 0%, #D9D9D9 100%)",
                                                borderTopRightRadius: 300,
                                            }}
                                            src={modalImage}
                                        />
                                        <img
                                            style={{
                                                width: "10%",
                                                height: "10%",
                                                // objectFit: "cover",
                                                position: "absolute",
                                                top: "50%",
                                                left: "40%",
                                                cursor: "pointer",
                                            }}
                                            src={playImg}
                                            onClick={() => this.openVideo()}
                                        />
                                        {/* <VideoPlay style={{ width: "100%", height: "100%" }} /> */}

                                        <div
                                            style={{
                                                width: 40,
                                                height: 40,
                                                padding: 2,
                                                right: 3,
                                                position: "absolute",
                                                background: "white",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "inline-flex",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => this.closeModal()}
                                        >
                                            <div
                                                style={{
                                                    width: 36,
                                                    height: 36,
                                                    position: "relative",
                                                    flexDirection: "column",
                                                    justifyContent: "flex-start",
                                                    alignItems: "flex-start",
                                                    display: "flex",
                                                }}
                                            >
                                                <img src={close} style={{ width: 36, height: 36 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" col-lg-6 col-md-12 order-lg-first">
                                    <div
                                        style={{
                                            width: "100%",
                                            //height: 400,
                                            position: "relative",
                                            paddingLeft: 20,
                                            marginBottom: 20,
                                        }}
                                    >
                                        <div
                                            style={{
                                                // alignSelf: "stretch",
                                                color: "#0F0E0E",
                                                fontSize: 25,
                                                fontFamily: "Inter",
                                                fontWeight: "700",
                                                wordWrap: "break-word",
                                                marginTop: 20,
                                                textAlign: "left",
                                                fontStyle: "normal",
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    this.props.t(`bannerThank_5`) +
                                                    "<span style='font-size:35px'>" +
                                                    this.props.score +
                                                    "</span>" +
                                                    this.props.t(`bannerThank_5_2`),
                                            }}
                                        ></div>

                                        <br></br>
                                        <div
                                            style={{
                                                color: "#DA291C",
                                                fontWeight: 600,
                                                textAlign: "left",
                                                fontFamily: "Inter",
                                                wordWrap: "break-word",
                                            }}
                                        >
                                            { this.props.t("bannerThank_2")}
                                        </div>
                                        <br></br>
                                        <div
                                            style={{
                                                fontFamily: "Inter",
                                                color: "#0F0E0E",
                                                fontSize: 20,
                                                textAlign: "justify",
                                            }}
                                        >
                                            <div
                                                style={{ marginBottom: 15 }}
                                                dangerouslySetInnerHTML={{
                                                    __html:  this.props.t(this.message_vertical),
                                                }}
                                            ></div>

                                            <div style={{ marginBottom: 15 }}></div>
                                        </div>
                                        <div
                                            style={{
                                                fontSize: 16,
                                                fontFamily: "Inter",
                                                textAlign: "justify",
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html:  this.props.t(this.message_thanks),
                                            }}
                                        ></div>
                                        <br></br>
                                        <div
                                            style={{
                                                fontSize: 16,
                                                fontFamily: "Inter",
                                                textAlign: "justify",
                                                backgroundColor: "white",
                                            }}
                                        >
                                            <button
                                                className="buttonEnable"
                                                onClick={() => this.closeModal()}
                                            >
                                                <div
                                                    style={{
                                                        //color: "#DA291C",
                                                        fontSize: 16,
                                                        fontFamily: "Inter",
                                                        fontWeight: "600",
                                                        lineHeight: 1,
                                                        wordWrap: "break-word",
                                                    }}
                                                >
                                                    { this.props.t("bannerThank_button")}
                                                </div>
                                            </button>
                                            <div
                                                style={{ marginTop: 15 }}
                                                dangerouslySetInnerHTML={{
                                                    __html:  this.props.t(`bannerThank_4`),
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
    }

}

export default ModalThanks