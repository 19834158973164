
/* eslint-disable */
import logo from "./Fortinet logo.svg";

import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import thank from "./assets/images/thank.png";
import { useTranslation } from "react-i18next";
const Thank = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="fullpage_background_color">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "left",
            height: "95.5vh",
            flex: 1,
            marginLeft: "2%",
            flexDirection: "column",
          }}
        >
          <img
            src={logo}
            alt=""
            style={{
              width: "50%",
              height: "24px",
              textalign: "center",
              position: "absolute",
              // top: "20%",
              marginTop: "15px",
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "left",
              height: "95.5vh",
              flex: 1,
              marginLeft: "10%",
              flexDirection: "column",
            }}
          >
            <h1 className="thankTextOne">{t("Thankyou_1")} </h1>
            <h1 className="thankTextTwo">
              {/* We appreciate your initiative in submitting your request. Your
              input is important to us, and our team will carefully consider the
              details you've provided. */}
              {t("Thankyou_2")}
            </h1>
            {/* <button className="goToHomepage">Go to the Home Page</button> */}
            <button className="buttonHomeThank" onClick={() => navigate("/")}>
              <div
                style={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 8,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    fontSize: 15,
                    fontFamily: "Inter",
                    fontWeight: "600",
                    lineHeight: 1,
                    wordWrap: "break-word",
                  }}
                >
                  {t("Thankyou_3")}
                </div>
                <BsArrowRight />
              </div>
            </button>
          </div>
        </div>
        <div className="columnThank">
          <img
            src={thank}
            alt=""
            width="524px"
            height="100%"
            className="imageTag"
          />
          <div className="redbox"></div>
          <div className="bluebox"></div>
          <div className="blueboxTwo"></div>
          <div className="lightbox"></div>
          <div className="lightTwobox"></div>
          <div className="redTwo"></div>
          <div className="dot"></div>
        </div>
      </div>
    </div>
  );
};

export default Thank;
