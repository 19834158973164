/* eslint-disable */

import "./Forms.css"
import { useTranslation } from "react-i18next";
import React, {useEffect, useState} from "react";
import { useRef } from 'react';
import { PatternFormat } from 'react-number-format';
export function Forms({Submitted,type}) {
  let ref = useRef({});
  const { t } = useTranslation();
  const [inputs, setInputs] = useState({
    firstname: null,
    lastname: null,
    jobtitle: null,
    phone: null,
    company: null,
    country: null,
    explicitConsent1: false,
    international_code:null
  });
  const [errors, setErrors] = useState({});
  const [is_valid,setIsValid]=useState(false)
  const [accept_agreement,setAcceptAgreement]=useState('accept')
  const [international_code,setInternationalCode] = useState([])
  const handleSubmit = (event) => {
    event.preventDefault();
    let result=Validation(inputs)
    let validation = result[0]
    let error = result[1]
    setErrors(validation)
    console.log(error)
    if(error){
      return
    }
    Submitted(inputs)


  }




  const handleChange =  (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))


  }
  useEffect(() => {
    let result =   Validation(inputs)
    let error = result[1]
    if (!error) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [inputs]);
  useEffect(() => {
    var data = require('./telephone.json');
    setInternationalCode(data)

  }, [false]);
  const handleCheckboxChange = async (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    console.log(name,event)
    setInputs(values => ({...values, [name]: value}))


  }

  const Validation =(inputValues) => {
    let errors = {}
    let is_error = false;
    console.log("Validation:",inputValues)
    console.log(inputValues.country)
    if ((inputValues.firstname == null) || (inputValues.firstname == '')) {
      errors.firstname = true
      is_error=true
    }else{
      errors.firstname = false
    }
    if ((inputValues.lastname == null) || (inputValues.lastname == '')) {
      errors.lastname = true
      is_error=true
    }else{
      errors.lastname = false
    }
    if ((inputValues.jobtitle == null) || (inputValues.jobtitle == '')) {
      errors.jobtitle = true
      is_error=true
    }else{
      errors.jobtitle = false
    }
    if ((inputValues.company == null) || (inputValues.company == '')) {
      errors.company = true
      is_error=true
    }else{
      errors.company = false
    }
    if ((inputValues.country == null) || (inputValues.country == '')) {
      errors.country = true
      is_error=true
    }else{
      errors.country = false
    }
    if ((inputValues.international_code == null) ) {
      errors.international_code = true
      is_error=true
    }else{
      errors.international_code = false
    }
    if ((inputValues.phone == null) || (inputValues.phone == '')) {
      errors.phone = true
      is_error=true
    }else{
      console.log(inputValues.phone.length)
      if(inputValues.phone.length<10){
        errors.phone_length =true
        is_error = true

      }else{
        errors.phone_length =false
      }
      errors.phone = false
    }
    if(!inputValues.explicitConsent1){

      errors.explicitConsent1=true
      is_error=true
    }else {
      errors.explicitConsent1=false
    }

    console.log(is_error)
    return [errors,is_error];
  }
  useEffect(() => {
      if (type ==='cloud'){
          setAcceptAgreement('accept_cloud')
      }
  }, [false]);
  return (
    <form onSubmit={handleSubmit} noValidate >
      <div className="row">
        <div className="col-lg-6 col-12">
          <div className="mb-lg-5 mb-3">
            <label htmlFor="firstname">{t("firstName")}</label>
            <input type="text" className="form-control p-0" id="firstname" name="firstname" onChange={handleChange}></input>
            {errors.firstname ? (
              <div className="col-12 text-center">
                <span className="LV_validation_message LV_invalid">
                   {t('This is required')}
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="mb-lg-5 mb-3">
            <label htmlFor="lastname">{t("lastName")}</label>
            <input type="text" className="form-control p-0" id="lastname" name="lastname" onChange={handleChange}></input>
            {errors.lastname ? (
              <div className="col-12 text-center">
                <span className="LV_validation_message LV_invalid">
                  {t('This is required')}
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="mb-lg-5 mb-3">
            <label htmlFor="jobTitle">  {t("jobTitle")}</label>
            <input type="text" className="form-control p-0 " id="jobTitle" name="jobtitle" onChange={handleChange}></input>
            {errors.jobtitle ? (
              <div className="col-12 text-center">
                <span className="LV_validation_message LV_invalid">
                   {t('This is required')}
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className=" ">
            <label htmlFor="phone">{t("phone")}</label>
            <div className='row'>
              <div className='col-md-4 col-12  mt-md-0 mb-3'>
                <select className='icon-flag' name='international_code' defaultValue={null} onChange={handleChange}>
                  <option disabled selected value>{t("selectCountry")}</option>
                  {international_code.map((element, i) => {
                    return (
                        <option key={i} title={element['name']}
                                value={element['dial_code']}>{element['name']} {element['emoji']} ({element['dial_code']}) </option>
                    )
                  })}
                </select>
                {errors.international_code ? (
                    <div className="col-12 text-center">
                <span className="LV_validation_message LV_invalid">
                 {t('This is required')}
                </span>
                    </div>
                ) : null}
              </div>

              <div className='col-md-8 col-12 mt-md-0 mb-3'>
                <PatternFormat id="phone" name="phone" format="(###) ###-####" allowEmptyFormatting
                               onValueChange={(values, sourceInfo) => {
                                 console.log(values.value, sourceInfo);
                                 setInputs(values_input => ({...values_input, 'phone': values.value}))
                               }}/>
                {errors.phone ? (
                    <div className="col-12 text-center">
                <span className="LV_validation_message LV_invalid">
                 {t('This is required')}
                </span>
                    </div>
                ) : null}
              </div>
            </div>

            {(!errors.phone && errors.phone_length) ? (
                <div className="col-12 text-center">
                <span className="LV_validation_message LV_invalid">
                  Minimum 10 digit
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="mb-lg-5 mb-3">
            <label htmlFor="company">{t("company")}</label>
            <input type="text" className="form-control p-0" id="company" name="company" onChange={handleChange}></input>
            {errors.company ? (
              <div className="col-12 text-center">
                <span className="LV_validation_message LV_invalid">
                  This is required
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="mb-lg-5 mb-3">
            <label htmlFor="floatingInput">    {t("country")}</label>
            <select className="form-select p-0" name="country" onChange={handleChange}>
              <option  value=''>{t("selectCountry")}</option>
              <option value="United States">
                United States
              </option>
              <option value="Canada">Canada</option>
              <option value="Afghanistan">
                Afghanistan
              </option>
              <option value="Aland Islands">
                Aland Islands
              </option>
              <option value="Albania">Albania</option>
              <option value="Algeria">Algeria</option>
              <option value="American Samoa">
                American Samoa
              </option>
              <option value="Andorra">Andorra</option>
              <option value="Angola">Angola</option>
              <option value="Anguilla">Anguilla</option>
              <option value="Antarctica">
                Antarctica
              </option>
              <option value="Antigua and Barbuda">
                Antigua and Barbuda
              </option>
              <option value="Argentina">
                Argentina
              </option>
              <option value="Armenia">Armenia</option>
              <option value="Aruba">Aruba</option>
              <option value="Australia">
                Australia
              </option>
              <option value="Austria">Austria</option>
              <option value="Azerbaijan">
                Azerbaijan
              </option>
              <option value="Bahamas">Bahamas</option>
              <option value="Bahrain">Bahrain</option>
              <option value="Bangladesh">
                Bangladesh
              </option>
              <option value="Barbados">Barbados</option>
              <option value="Belarus">Belarus</option>
              <option value="Belgium">Belgium</option>
              <option value="Belize">Belize</option>
              <option value="Benin">Benin</option>
              <option value="Bermuda">Bermuda</option>
              <option value="Bhutan">Bhutan</option>
              <option value="Bolivia">Bolivia</option>
              <option value="Bonaire, Sint Eustatius and Saba">
                Bonaire, Sint Eustatius and Saba
              </option>
              <option value="Bosnia and Herzegovina">
                Bosnia and Herzegovina
              </option>
              <option value="Botswana">Botswana</option>
              <option value="Bouvet Island">
                Bouvet Island
              </option>
              <option value="Brazil">Brazil</option>
              <option value="British Indian Ocean Territory">
                British Indian Ocean Territory
              </option>
              <option value="British Virgin Islands">
                British Virgin Islands
              </option>
              <option value="Brunei">Brunei</option>
              <option value="Bulgaria">Bulgaria</option>
              <option value="Burkina Faso">
                Burkina Faso
              </option>
              <option value="Burundi">Burundi</option>
              <option value="Cambodia">Cambodia</option>
              <option value="Cameroon">Cameroon</option>
              <option value="Canada">Canada</option>
              <option value="Cape Verde">
                Cape Verde
              </option>
              <option value="Cayman Islands">
                Cayman Islands
              </option>
              <option value="Central African Republic">
                Central African Republic
              </option>
              <option value="Chad">Chad</option>
              <option value="Chile">Chile</option>
              <option value="China">China</option>
              <option value="Christmas Island">
                Christmas Island
              </option>
              <option value="Cocos Islands">
                Cocos Islands
              </option>
              <option value="Colombia">Colombia</option>
              <option value="Comoros">Comoros</option>
              <option value="Cook Islands">
                Cook Islands
              </option>
              <option value="Costa Rica">
                Costa Rica
              </option>
              <option value="Croatia">Croatia</option>
              <option value="Cuba">Cuba</option>
              <option value="Curacao">Curacao</option>
              <option value="Cyprus">Cyprus</option>
              <option value="Czech Republic">
                Czech Republic
              </option>
              <option value="Congo, The Democratic Republic Of The">
                Congo, The Democratic Republic Of The
              </option>
              <option value="Denmark">Denmark</option>
              <option value="Djibouti">Djibouti</option>
              <option value="Dominica">Dominica</option>
              <option value="Dominican Republic">
                Dominican Republic
              </option>
              <option value="Ecuador">Ecuador</option>
              <option value="Egypt">Egypt</option>
              <option value="El Salvador">
                El Salvador
              </option>
              <option value="Equatorial Guinea">
                Equatorial Guinea
              </option>
              <option value="Eritrea">Eritrea</option>
              <option value="Estonia">Estonia</option>
              <option value="Ethiopia">Ethiopia</option>
              <option value="Falkland Islands">
                Falkland Islands
              </option>
              <option value="Faroe Islands">
                Faroe Islands
              </option>
              <option value="Fiji">Fiji</option>
              <option value="Finland">Finland</option>
              <option value="France">France</option>
              <option value="French Guiana">
                French Guiana
              </option>
              <option value="French Polynesia">
                French Polynesia
              </option>
              <option value="French Southern Territories">
                French Southern Territories
              </option>
              <option value="Gabon">Gabon</option>
              <option value="Gambia">Gambia</option>
              <option value="Georgia">Georgia</option>
              <option value="Germany">Germany</option>
              <option value="Ghana">Ghana</option>
              <option value="Gibraltar">
                Gibraltar
              </option>
              <option value="Greece">Greece</option>
              <option value="Greenland">
                Greenland
              </option>
              <option value="Grenada">Grenada</option>
              <option value="Guadeloupe">
                Guadeloupe
              </option>
              <option value="Guam">Guam</option>
              <option value="Guatemala">
                Guatemala
              </option>
              <option value="Guernsey">Guernsey</option>
              <option value="Guinea">Guinea</option>
              <option value="Guinea-Bissau">
                Guinea-Bissau
              </option>
              <option value="Guyana">Guyana</option>
              <option value="Haiti">Haiti</option>
              <option value="Heard Island and McDonald Islands">
                Heard Island and McDonald Islands
              </option>
              <option value="Holy See (Vatican City State)">
                Holy See (Vatican City State)
              </option>
              <option value="Honduras">Honduras</option>
              <option value="Hong Kong">
                Hong Kong
              </option>
              <option value="Hungary">Hungary</option>
              <option value="Iceland">Iceland</option>
              <option value="India">India</option>
              <option value="Indonesia">
                Indonesia
              </option>
              <option value="Iran, Islamic Republic of">
                Iran, Islamic Republic of
              </option>
              <option value="Iraq">Iraq</option>
              <option value="Ireland">Ireland</option>
              <option value="Isle of Man">
                Isle of Man
              </option>
              <option value="Israel">Israel</option>
              <option value="Italy">Italy</option>
              <option value="Cote D'Ivoire">
                Cote D'Ivoire
              </option>
              <option value="Jamaica">Jamaica</option>
              <option value="Japan">Japan</option>
              <option value="Jersey">Jersey</option>
              <option value="Jordan">Jordan</option>
              <option value="Kazakhstan">
                Kazakhstan
              </option>
              <option value="Kenya">Kenya</option>
              <option value="Kiribati">Kiribati</option>
              <option value="Kosovo">Kosovo</option>
              <option value="Kuwait">Kuwait</option>
              <option value="Kyrgyzstan">
                Kyrgyzstan
              </option>
              <option value="Laos">Laos</option>
              <option value="Latvia">Latvia</option>
              <option value="Lebanon">Lebanon</option>
              <option value="Lesotho">Lesotho</option>
              <option value="Liberia">Liberia</option>
              <option value="Libya">Libya</option>
              <option value="Liechtenstein">
                Liechtenstein
              </option>
              <option value="Lithuania">
                Lithuania
              </option>
              <option value="Luxembourg">
                Luxembourg
              </option>
              <option value="Macau">Macau</option>
              <option value="Republic of North Macedonia">
                Republic of North Macedonia
              </option>
              <option value="Madagascar">
                Madagascar
              </option>
              <option value="Malawi">Malawi</option>
              <option value="Malaysia">Malaysia</option>
              <option value="Maldives">Maldives</option>
              <option value="Mali">Mali</option>
              <option value="Malta">Malta</option>
              <option value="Mashall Islands">
                Mashall Islands
              </option>
              <option value="Martinique">
                Martinique
              </option>
              <option value="Mauritania">
                Mauritania
              </option>
              <option value="Mauritius">
                Mauritius
              </option>
              <option value="Mayotte">Mayotte</option>
              <option value="Mexico">Mexico</option>
              <option value="Micronesia">
                Micronesia
              </option>
              <option value="Moldova, Republic of">
                Moldova, Republic of
              </option>
              <option value="Monaco">Monaco</option>
              <option value="Mongolia">Mongolia</option>
              <option value="Montenegro">
                Montenegro
              </option>
              <option value="Montserrat">
                Montserrat
              </option>
              <option value="Morocco">Morocco</option>
              <option value="Mozambique">
                Mozambique
              </option>
              <option value="Myanmar">Myanmar</option>
              <option value="Namibia">Namibia</option>
              <option value="Nauru">Nauru</option>
              <option value="Nepal">Nepal</option>
              <option value="Netherlands Antilles">
                Netherlands Antilles
              </option>
              <option value="Netherlands">
                Netherlands
              </option>
              <option value="New Caledonia">
                New Caledonia
              </option>
              <option value="New Zealand">
                New Zealand
              </option>
              <option value="Nicaragua">
                Nicaragua
              </option>
              <option value="Niger">Niger</option>
              <option value="Nigeria">Nigeria</option>
              <option value="Niue">Niue</option>
              <option value="Norfolk Island">
                Norfolk Island
              </option>
              <option value="Northern Mariana Islands">
                Northern Mariana Islands
              </option>
              <option value="Norway">Norway</option>
              <option value="Oman">Oman</option>
              <option value="Pakistan">Pakistan</option>
              <option value="Palau">Palau</option>
              <option value="Palestine, State of">
                Palestine, State of
              </option>
              <option value="Panama">Panama</option>
              <option value="Papua New Guinea">
                Papua New Guinea
              </option>
              <option value="Paraguay">Paraguay</option>
              <option value="Peru">Peru</option>
              <option value="Philippines">
                Philippines
              </option>
              <option value="Pitcairn Islands">
                Pitcairn Islands
              </option>
              <option value="Poland">Poland</option>
              <option value="Portugal">Portugal</option>
              <option value="Qatar">Qatar</option>
              <option value="Congo">Congo</option>
              <option value="Reunion">Reunion</option>
              <option value="Romania">Romania</option>
              <option value="Russian Federation">
                Russian Federation
              </option>
              <option value="Rwanda">Rwanda</option>
              <option value="Saint Barthelemy">
                Saint Barthelemy
              </option>
              <option value="Saint Helena">
                Saint Helena
              </option>
              <option value="Saint Kitts and Nevis">
                Saint Kitts and Nevis
              </option>
              <option value="Saint Lucia">
                Saint Lucia
              </option>
              <option value="Saint Martin">
                Saint Martin
              </option>
              <option value="Saint Pierre and Miquelon">
                Saint Pierre and Miquelon
              </option>
              <option value="Saint Vincent and the Grenadines">
                Saint Vincent and the Grenadines
              </option>
              <option value="Samoa">Samoa</option>
              <option value="San Marino">
                San Marino
              </option>
              <option value="Sao Tome and Principe">
                Sao Tome and Principe
              </option>
              <option value="Saudi Arabia">
                Saudi Arabia
              </option>
              <option value="Senegal">Senegal</option>
              <option value="Serbia">Serbia</option>
              <option value="Seychelles">
                Seychelles
              </option>
              <option value="Sierra Leone">
                Sierra Leone
              </option>
              <option value="Singapore">
                Singapore
              </option>
              <option value="Sint Maarten (Dutch part)">
                Sint Maarten (Dutch part)
              </option>
              <option value="Slovakia">Slovakia</option>
              <option value="Slovenia">Slovenia</option>
              <option value="Solomon Islands">
                Solomon Islands
              </option>
              <option value="Somalia">Somalia</option>
              <option value="South Africa">
                South Africa
              </option>
              <option value="South Georgia and the South Sandwich Islands">
                South Georgia and the South Sandwich
                Islands
              </option>
              <option value="South Korea">
                South Korea
              </option>
              <option value="South Sudan">
                South Sudan
              </option>
              <option value="Spain">Spain</option>
              <option value="Sri Lanka">
                Sri Lanka
              </option>
              <option value="Sudan">Sudan</option>
              <option value="Suriname">Suriname</option>
              <option value="Svalbard and Jan Mayen">
                Svalbard and Jan Mayen
              </option>
              <option value="Swaziland">
                Swaziland
              </option>
              <option value="Sweden">Sweden</option>
              <option value="Switzerland">
                Switzerland
              </option>
              <option value="Syrian Arab Republic">
                Syrian Arab Republic
              </option>
              <option value="Taiwan">Taiwan</option>
              <option value="Tajikistan">
                Tajikistan
              </option>
              <option value="Tanzania, United Republic of">
                Tanzania, United Republic of
              </option>
              <option value="Thailand">Thailand</option>
              <option value="Timor-Leste">
                Timor-Leste
              </option>
              <option value="Togo">Togo</option>
              <option value="Tokelau">Tokelau</option>
              <option value="Tonga">Tonga</option>
              <option value="Trinidad and Tobago">
                Trinidad and Tobago
              </option>
              <option value="Tunisia">Tunisia</option>
              <option value="Turkey">Turkey</option>
              <option value="Turkmenistan">
                Turkmenistan
              </option>
              <option value="Turks and Caicos Islands">
                Turks and Caicos Islands
              </option>
              <option value="Tuvalu">Tuvalu</option>
              <option value="Uganda">Uganda</option>
              <option value="Ukraine">Ukraine</option>
              <option value="United Arab Emirates">
                United Arab Emirates
              </option>
              <option value="United Kingdom">
                United Kingdom
              </option>

              <option value="Uruguay">Uruguay</option>
              <option value="US Virgin Islands">
                US Virgin Islands
              </option>
              <option value="Uzbekistan">
                Uzbekistan
              </option>
              <option value="Vanuatu">Vanuatu</option>
              <option value="Venezuela">
                Venezuela
              </option>
              <option value="Vietnam">Vietnam</option>
              <option value="Wallis and Futuna">
                Wallis and Futuna
              </option>
              <option value="Western Sahara">
                Western Sahara
              </option>
              <option value="Yemen">Yemen</option>
              <option value="Zambia">Zambia</option>
              <option value="Zimbabwe">Zimbabwe</option>
            </select>
            {errors.country ? (
                <div className="col-12 text-center">
                <span className="LV_validation_message LV_invalid">
                  This is required
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-lg-10 mb-lg-5 mb-3">
          <div className="row justify-content-center">
              <div className="col-12 text-start" style={{display:'inline-flex',alignItems:'center'}}>
                <input
                    type="checkbox"
                    name="explicitConsent1"
                    onChange={handleCheckboxChange}
                    className="form-check-input"

                />
                <span>
                      <label className="checkbox-aligned elq-item-label">  {t(accept_agreement)}</label>
                </span>
              </div>
            <div className="col-11">

            {errors.explicitConsent1 ? (
                  <div className="col-12 text-center">
                <span className="LV_validation_message LV_invalid">
                  You need to accept
                </span>
                  </div>
              ) : null}
            </div>
          </div>

        </div>
        <div className="col-lg-2 col-12 ">
          <button type="submit" className={"btn white " +(is_valid ? 'bg-red':'bg-gray')}>{t("evaluate")}</button>
        </div>

      </div>
    </form>

  )
}

