
/* eslint-disable */
import React, { useState, useEffect, useRef, createRef } from 'react';
import { useTranslation } from "react-i18next";
import './questions.css'
import { Answers } from './answers';
import {Industry} from "./industry";
import {BsArrowLeft, BsArrowRight} from "react-icons/bs";

export function Question({ title, type,Submitted,has_industry,back }) {
    const { t, i18n } = useTranslation();
    const [index, setIndex] = useState(1)
    const [selectedAnswer,setSelectedAnswer] = useState(false)
    const [answerQuestions, setAnswerQuestions] = useState({})
    const [tempSelection,setTempSelection]=useState([])
    const [questions, setQuestions] = useState([])
    const [industry,setIndustry] = useState(null)
    const elementsRef = useRef(questions.map(() => createRef()));

    useEffect(() => {
        var data = require('./Questions.json');
        var array_questions = data[type]

        if (!array_questions) {
            return
        }
        setQuestions(array_questions)
        console.log(tempSelection[index])
    },[false]);

    const FinishQuestions=()=>{
        console.log(tempSelection)
        let score=0
        for (let i = 0; i < tempSelection.length; i++) {
            score = (score+parseInt(tempSelection[i]))

        }
        score=score/tempSelection.length
        console.log(score)
        score=Math.round(score)
        console.log(score)
        if(!has_industry){
            setIndustry(null)
        }
        Submitted(score,tempSelection,industry)
    }

    const NextQuestion = () => {
        setSelectedAnswer(false)
        setIndex(index + 1)
    }

    const PrevQuestion = () => {
        setSelectedAnswer(true)
        setIndex(index - 1)
        console.log(index)
        if(index - 1==0){
            back()
        }
    }

    const getPorcent = () => {
        return ((index) * 100) / questions.length
    }

    const SelectAnswer = (value) =>{
        //setTempSelection(tempSelection[index-1]=null)
        console.log(tempSelection[index-1])
        let selection = tempSelection
        selection[index-1]=value
        setTempSelection(selection)
        console.log(tempSelection)
        setSelectedAnswer(true)
        
    }


    return (

        <div className="col-12">
                    <div className="row  justify-content-center">
                        <div className='col-lg-10 col-12'>

                            <div className='row my-3'>

                                <div className="col-10 fs-3" dangerouslySetInnerHTML={{__html:t('answer_question_text')}}>
                                </div>
                                <div className="col-2 fs-3 red text-center">
                                    {index}/{questions.length}
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className='row '>
                        <div className='col-12' style={{ height: 8 }}>
                            <div
                                style={{
                                    width: getPorcent() + "%",
                                    height: 8,
                                    background:
                                        "linear-gradient(270deg, #DA291C 0%, rgba(218, 41, 28, 0) 100%)",
                                    borderTopLeftRadius: 10,
                                    borderTopRightRadius: 10,
                                }}
                            />
                        </div>
                    </div>
                    <div className="row justify-content-center bg-gray-2 pt-5" >

                        <div className='col-lg-10 col-12'>
                            {
                                questions.map((element, i) => {
                                    return (
                                        <div key={i} className={'fs-4  my-3 ' + (index === i + 1 ? '' : 'd-none')}>
                                            {
                                                <Answers question={element['title']} index={i+1} answers={element['answer']} clickFunction={SelectAnswer}></Answers>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='row justify-content-center bg-gray-2' >
                        <div className='col-lg-9 col-12'>



                                    <div className='row btn-grid justify-content-end mb-4'>
                                        <div className={'col-md-8 col-12 dont_know '+(index>=2?'show':'hide')}  dangerouslySetInnerHTML={{__html:t('dont_know_answer')}}>

                                        </div>
                                        <div className='col-md-2 col-6'>
                                            <button onClick={PrevQuestion} className={'btn enable'}>
                                                <BsArrowLeft/> &nbsp;
                                                <span className='fs-6'>{t("previousbtn")}</span>
                                            </button>

                                        </div>
                                        <div className='col-md-2 col-6'>
                                    {index >=questions.length ? (
                                    <button onClick={FinishQuestions}
                                            disabled={(!selectedAnswer)}
                                        className={'btn enable'}>
                                    <span className='fs-6'>{t(`nextbtn`)}&nbsp;&nbsp;
                                        <BsArrowRight /></span>
                                    </button>

                                    ) : (
                                    <button onClick={NextQuestion}
                                        disabled={(index >= questions.length) || (!selectedAnswer)}
                                        className={'btn ' + (index >= questions.length ? 'disable' : 'enable')}>
                                    <span className='fs-6'>{t(`nextbtn`)}&nbsp;&nbsp;
                                        <BsArrowRight /> </span>


                                    </button>
                                    )}

                                    </div>
                                    </div>



                        </div>
                    </div>
                </div>


    )
}
