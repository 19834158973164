/* eslint-disable */

import {Industries} from "../Industries/industries";
import React, {useRef, useState} from "react";
import {Question} from "../Questions/questions";
import {ModalEmail2} from "../Modals/modal_email2";
import ModalThanks from "../Modals/modal_thanks";
import ModalVideo from "../Modals/modal_video";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";


export function FormQuestions({type,inputs}) {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const [industry, setIndustry] = useState(null)
    const modalEmail = useRef(null);
    const childFunc = React.useRef(null)
    const [score, setScore] = useState(0)
    const [indexIndustry, setIndexIndustry] = useState()
    const [input, setInputs] = useState({})
    const OpenModalEmail = (score, questions) => {
        setScore(score)
        console.log(inputs)
        childFunc.current(inputs, score, questions, industry)
    }

    const showIndustry = () => {
        setIndustry(null)
    }
    const validateEmail = (email) => {
        if (
            /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/.test(
                email
            )
        ) {
            return true;
        }
        return false;
    };
    const closeModal = () => {
        var companyEmail = modalEmail.current.state.companyEmail
        console.log(companyEmail)
        if (!validateEmail(companyEmail)) {
            document.getElementById("email_message").className =
                "LV_validation_message LV_invalid";
            document.getElementById("email_message_span").innerHTML =
                "Please use your corporate E-mail";
            return
        }


    }
    const getScore = () => {
        return score
    }
    const openVideo = () => {
        // document.getElementById("backdrop").style.display = "block";
        document.getElementById("modalThanks").style.display = "none";
        document.getElementById("videoPlay").style.display = "block";
        document.getElementById("videoPlay").classList.add("show");
    };

    const closeVideoPlay = () => {
        document.getElementById("modalThanks").style.display = "block";
        document.getElementById("videoPlay").style.display = "none";
        document.getElementById("videoPlay").classList.add("hide");
    };

    const videos = {
        "OT": {
            en: 'https://www.youtube.com/embed/eqnW0GYKvWU?si=muXFJ0oPDLSL2Sn5&rel=0',
            es: 'https://www.youtube.com/embed/OHUillgiFcw?si=FHCaLahRLFJQ7WIL&rel=0',
            pt: 'https://www.youtube.com/embed/g2Tdnx2PN_A?si=ZOP10pDkz8G6gnO4&rel=0',
            fr: 'https://www.youtube.com/embed/eqnW0GYKvWU?si=3yqMepIldTv9jKJV&rel=0',
        },
        "AR": {
            en: 'https://www.youtube.com/embed/HEadork-hPo?si=c5TmArE8pc5_BqOx&rel=0',
            es: 'https://www.youtube.com/embed/HEadork-hPo?si=c5TmArE8pc5_BqOx&rel=0',
            pt: 'https://www.youtube.com/embed/HEadork-hPo?si=c5TmArE8pc5_BqOx&rel=0',
            fr: 'https://www.youtube.com/embed/HEadork-hPo?si=c5TmArE8pc5_BqOx&rel=0',
        }, "CLOUD": {
            en: 'https://www.youtube.com/embed/zIaLP9_ilCA?si=MBpdZMn9l7YTAH3a&rel=0',
            es: 'https://www.youtube.com/embed/zIaLP9_ilCA?si=MBpdZMn9l7YTAH3a&rel=0',
            pt: 'https://www.youtube.com/embed/zIaLP9_ilCA?si=MBpdZMn9l7YTAH3a&rel=0',
            fr: 'https://www.youtube.com/embed/zIaLP9_ilCA?si=MBpdZMn9l7YTAH3a&rel=0',
        }, "SO": {
            en: 'https://www.youtube.com/embed/xqzjNQBy0TE?si=OFK-vvv9JLnjPZYn&amp&rel=0',
            es: 'https://www.youtube.com/embed/xqzjNQBy0TE?si=OFK-vvv9JLnjPZYn&amp&rel=0',
            pt: 'https://www.youtube.com/embed/xqzjNQBy0TE?si=OFK-vvv9JLnjPZYn&amp&rel=0',
            fr: 'https://www.youtube.com/embed/xqzjNQBy0TE?si=OFK-vvv9JLnjPZYn&amp&rel=0',
        }
    }

    const openModalThanks = () => {
        // document.getElementById("backdrop").style.display = "block";
        document.getElementById("modalThanks").style.display = "block";
        document.getElementById("modalThanks").classList.add("show");

    };
    const closeModalThanks = () => {
        //document.getElementById("backdrop").style.display = "none";
        document.getElementById("modalThanks").style.display = "none";
        document.getElementById("modalThanks").classList.remove("show");
        navigate("/");
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    const SetIndustryConfirm = (industry, index) => {
        setIndexIndustry(index)
        setIndustry(industry)
    }
    return (
        <div style={{borderTop: '1px solid rgba(218, 41, 28, 0.2)', paddingTop: "1rem", marginTop: "2rem"}}>
            <div className={"row justify-content-center"}>
                {industry == null ?
                    <Industries type={type} submitted={SetIndustryConfirm} industry_index={indexIndustry}>

                    </Industries>
                    : <Question type={type} back={showIndustry} Submitted={OpenModalEmail}>

                    </Question>

                }

            </div>

            <ModalEmail2 childtrigger={childFunc} score={getScore()} closeTrigger={openModalThanks}
                         vertical={type}></ModalEmail2>
            <ModalThanks score={getScore()} closeModalThanks={closeModalThanks} openVideo={openVideo} t={t}
                         vertical={type}/>
            <ModalVideo closeVideoPlay={closeVideoPlay} videos={videos} language={i18n.language} vertical={type}/>
        </div>

    )
}