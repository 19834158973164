/* eslint-disable */

import React, {useRef, useState} from "react";

import image_1 from "../../assets/images/Other_banner.jpg";
import image_2 from "../../assets/images/manufacturing_banner.jpg";
import image_3 from "../../assets/images/Transportation&Logistics_banner.jpeg";
import image_4 from "../../assets/images/mining_banner.jpg";
import image_5 from "../../assets/images/Power_Utilities_banner.jpg";
import image_6 from "../../assets/images/oil-and-gas_banner.jpg";
import image_7 from "../../assets/images/manufacturing_banner.jpg";
import { Forms } from "../../Components/Forms/Forms"
import dot from "../../assets/images/dot.png";


import {useTranslation} from "react-i18next";

import { useNavigate } from "react-router-dom";
import question from "../../assets/images/Questionnaire.gif";
import evaluation from "../../assets/images/evaluation.gif";
import recommend from "../../assets/images/recommend.gif";
import {FormQuestions} from "../../Components/Forms/Form_questions";
export default function OT() {
  const { t, i18n } = useTranslation();
  const modalEmail = useRef(null);
  const childFunc = React.useRef(null)
  const [startQ, setStartQ] = useState(false)
  const [score,setScore] =useState(0)
  const [input,setInputs] = useState({})
  const navigate = useNavigate();
  const refFormQuestion = useRef(null);
  const OpenQuestions = (inputs) => {
    console.log(inputs)

    setInputs(inputs)
    setStartQ(true)
    refFormQuestion.current?.scrollIntoView()
  }
  return (
      <div className="mt-5">
        <div className="background-banner">
          <div id="carouselExampleAutoplaying" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="animationOne"></div>
            <div className="animationTwo"></div>
            <div className="animationThree"></div>
            <div className="animationFourFive">
              <div className="animationFour"></div>
              <div className="animationFive"></div>
            </div>
            <div className="animationSix"></div>
            <div className="animationSeven"></div>
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0"
                      className="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                      aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                      aria-label="Slide 3"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3"
                      aria-label="Slide 4"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4"
                      aria-label="Slide 5"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5"
                      aria-label="Slide 6"></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={image_1} className="d-block w-100 object-fit-cover object-position-top"
                     alt="..."></img>
                <div className="carousel-caption text-start">
                  <div className="row">
                    <div className="col-12">
                      <h1>{t('banner_main_OT')}</h1>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-8 col-12">
                      <p className="fs-3 lh-sm fw-semibold "
                         dangerouslySetInnerHTML={{
                           __html:t('banner_second_OT_1')
                         }}>
                      </p>
                    </div>
                  </div>



                </div>
              </div>

              <div className="carousel-item ">
                <img src={image_2} className="d-block w-100 object-fit-cover" alt="..."></img>
                <div className="carousel-caption text-start">
                  <div className="row">
                    <div className="col-12">
                      <h1>{t('banner_main_OT')}</h1>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-8 col-12">
                      <p className="fs-3 lh-sm fw-semibold "   dangerouslySetInnerHTML={{
                        __html:t('banner_second_OT_2')
                      }}>

                      </p>
                    </div>
                  </div>


                </div>
              </div>
              <div className="carousel-item">
                <img src={image_3} className="d-block w-100 object-fit-cover" alt="..."></img>
                <div className="carousel-caption text-start">
                  <div className="row">
                    <div className="col-12">
                      <h1>{t('banner_main_OT')}</h1>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-8 col-12">
                      <p className="fs-3 lh-sm fw-semibold "
                         dangerouslySetInnerHTML={{
                           __html:t('banner_second_OT_3')
                         }}>

                      </p>
                    </div>
                  </div>


                </div>
              </div>
              <div className="carousel-item">
                <img src={image_4} className="d-block w-100 object-fit-cover object-position-bottom"
                     alt="..."></img>
                <div className="carousel-caption text-start">
                  <div className="row">
                    <div className="col-12">
                      <h1>{t('banner_main_OT')}</h1>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-8 col-12">
                      <p className="fs-3 lh-sm fw-semibold "   dangerouslySetInnerHTML={{
                        __html:t('banner_second_OT_4')
                      }}></p>
                    </div>
                  </div>


                </div>
              </div>
              <div className="carousel-item">
                <img src={image_5} className="d-block w-100 object-fit-cover" alt="..."></img>
                <div className="carousel-caption text-start">
                  <div className="row">
                    <div className="col-12">
                      <h1>{t('banner_main_OT')}</h1>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-8 col-12">
                      <p className="fs-3 lh-sm fw-semibold "   dangerouslySetInnerHTML={{
                        __html:t('banner_second_OT_5')
                      }}></p>
                    </div>
                  </div>


                </div>
              </div>
              <div className="carousel-item">
                <img src={image_6} className="d-block w-100 object-fit-cover" alt="..."></img>
                <div className="carousel-caption text-start">
                  <div className="row">
                    <div className="col-12">
                      <h1>{t('banner_main_OT')}</h1>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-8 col-12">
                      <p className="fs-3 lh-sm fw-semibold " dangerouslySetInnerHTML={{
                        __html: t('banner_second_OT_6')
                      }}></p></div>
                  </div>


                </div>
              </div>

            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>

          </div>
        </div>
        <div className="form-container mt-n1">
          <div className="row justify-content-center">
            <div className="col-sm-10 col-12">
              <div className="user-form p-5  ms-3 me-3">
                <div className="fs-3">
                  {t("form_content1")} <span className="red">{t("form_content2")}</span>
                </div>
                <div className="mt-5">
                  <Forms Submitted={OpenQuestions}></Forms>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div className="cloud-content-3 mt-5 mx-3">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="row">
                <div className="col-lg-6 col-12 pe-lg-5">
                  <p className="fs-2 lh-sm">
                    <span className="red">{t('content1')} </span>{t('content2')}
                    <span className=""> {t('content3')} </span>{t('content4')} <span
                      className="red"> {t('content5')}</span>.

                  </p>
                </div>
                <div className="col-lg-6 col-12 pe-lg-5">
                  <p className="fs-6">
                    {t('content6')}
                  </p>
                  <p className="fs-6">
                    {t('content7')}
                  </p>
                </div>
              </div>


            </div>

          </div>
        </div>
        <div ref={refFormQuestion}>
          {startQ ? (
              <FormQuestions type={'OT'} inputs={input} ></FormQuestions>

          ) : null}
        </div>
        <div className="questionSection">
          <div className="questionWord">
            <span className="textQuestion">{t("content10")} </span>
            <span className="textQuestionRed">{t("content11")}</span>
            <span className="textQuestion"> {t("content12")}</span>
          </div>
          <div style={{width: "100%"}}>
            <div className="box arrow-bottom">
              <div style={{textAlign:'center'}} className="textOne">1</div>
              <div  className="textTwo">
                <div className="textMiddleSmall">
                  <div className="middleText">{t("question")}</div>
                  <div className="middleTextBelow">{t("questiontext")}</div>
                </div>
              </div>
              <div className="textThree">
                <div className="img-responsive">
                  <img src={question} className="imageSize"/>
                </div>
              </div>
            </div>
          </div>
          <div style={{width: "100%"}}>
            <div className="box arrow-bottom">
              <div style={{textAlign:'center'}} className="textOne">2</div>
              <div className="textTwo">
                <div className="textMiddleSmall">
                  <div className="middleText"> {t("Evaluation")}</div>
                  <div className="middleTextBelow"> {t("EvaluationText")}</div>
                </div>
              </div>
              <div className="textThree">
                <div className="img-responsive">
                  <img src={evaluation} className="imageSize"/>
                </div>
              </div>
            </div>
          </div>

          <div style={{width: "100%"}}>
            <div className="box">
              <div style={{textAlign:'center'}} className="textOne">3</div>
              <div className="textTwo">
                <div className="textMiddleSmall">
                  <div className="middleText"> {t("Recommendation")}</div>
                  <div className="middleTextBelow">
                    {" "}
                    {t("RecommendationText")}
                  </div>
                </div>
              </div>
              <div className="textThree">
                <div className="img-responsive">
                  <img src={recommend} className="imageSize"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="second-bg-img">
          <img src={image_7} className="bg1" width="100%" height="658"/>
          <div style={{position: "absolute", left: "50%", top: "20%"}}>
            <img src={dot} width="50%" height="50%"/>
          </div>
          <div className="animationEight"/>
          <div className="animationNine"/>
          <div className="animationTen"></div>
          <div className="animationEleven"/>
          <div className="animationTweleve"/>
          <div className="animationThirteen"/>
          <div className="animationFourteen"/>
        </div>

      </div>
  )
}