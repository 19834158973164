
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

export function Answers({question,answers,index,clickFunction}){
    const { t, i18n } = useTranslation();
    const [selectedValue,setSelectedValue] = useState({})
    const Selection =(value)=> {
        setSelectedValue(value)
        clickFunction(value)
    }
    return(
        <>
        <div className='row mx-2'>
            {index}. {t(question)}
        </div>
        <div className='row'>
            <div className='col-12'>
                {answers.map((element,index)=>{
                        return(
                            <div key={index} className="row m-2 text-justify my-3" >
                                <div className={"col-12 bg-white fs-6 pt-2 ps-3 pb-2 "+(selectedValue==element['value'] ? 'answerselected' : 'answer')} onClick={()=>Selection(element['value'])}>
                                    {t(element['label'])}
                                </div>
                            </div>
                        )
                })}
            </div>
        </div>
        </>
    )

}