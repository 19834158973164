/* eslint-disable */

import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Cookies from 'universal-cookie';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import reportWebVitals from "./reportWebVitals";
import "./i18n/config";
import PageNotFound from "./PageNotFound";
import Thank from "./Tankyou_Page";
import logo from "./Fortinet logo.svg";
import world from "./world.svg";
import { useTranslation } from "react-i18next";
import 'bootstrap-icons/font/bootstrap-icons.css';
import PageTitle from "./Components/PageTitle";
//Pages
import Home from "./pages/home/Home"
import OT from "./pages/OT/OT"
import Cloud from "./pages/Cloud/Cloud"
import AT from "./pages/AntiRansomware/AntiRansomware"
import SO from "./pages/SO/SO" 
// import EmailTemplate from "./emailtemplate/EmailTemplate";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
const cookies = new Cookies(null, { path: '/' });

function StartPage() {
    useEffect(() => {
        switch (window.location.pathname.toLowerCase()) {
            case "/":
                setPageName("");
                break;
            case "/ot":
                setPageName("OT Security");
                break;
            case "/cloud":
                setPageName("Cloud Security");
                break;
            case "/antiransomware":
                setPageName("Anti Ransomware");
                break;
            case "/secops":
                setPageName("Security Operations");
                break;
            default:
                setPageName("");
                break;
        }
    }, [window.location.pathname]);
    const { t, i18n } = useTranslation();
    const [selectedLang, setSelectedlang] = useState("English");
    const [pageName,setPageName] = useState('')
    const changeLanguage = (val, lang) => {
        cookies.set('lang', val);
        i18n.changeLanguage(val);
        setSelectedlang(val);
      };
    useEffect(()=>{
        var lang = cookies.get('lang')
        i18n.changeLanguage(lang)
        setSelectedlang(lang);
    },[false])
      return (
        <>
          <div className="social_header_menu fixed-top bg-light overflow-visible">
              {/* <div className="container-fluid"> */}
                <div className={'row align-items-center justify-content-center'} style={{height:'100%'}}>

                        <div className={'col-lg-2 col-sm-3 col-12 text-center'}>
                            <a className="navbar-brand ps-4" href="/">
                                <img src={logo} alt="" className={'img-fluid'}/>
                            </a>

                        </div>
                    <div className={'col-lg-8 col-sm-4 col-12 page-name' }>
                        <span>
                                  {pageName}
                            </span>
                    </div>
                    <div className={'col-lg-2 col-sm-4 col-9 text-center'}>
                        <div className='row justify-content-center'>

                                <div className="dropdown">
                                    <button
                                        className="btn dropdown-toggle no-border"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        style={{display: "flex", alignItems: 'center', flexWrap: "nowrap",margin:"auto"}}
                                        style={{display: "flex", alignItems: 'center', flexWrap: "nowrap",margin:"auto"}}
                                    >
            <span>
              <img src={world} width="24" className="img-fluid" alt=""/>
            </span>
                                        <span
                                            style={{
                                                color: "#0F0E0E",
                                                fontSize: 16,
                                                fontFamily: "Inter",
                                                fontWeight: "400",
                                                wordWrap: "break-word",
                                                display: "inline-block",
                                                paddingLeft: "7px",
                                            }} className={'text-wrap'}
                                        >
              {t('Select language')}
            </span>
                                    </button>
                                    <ul
                                        className="dropdown-menu dropdown-menu-end customCss"
                                        data-bs-popper="none"
                                    >
                                        <li>
                                            <a
                                                className={
                                                    selectedLang == "en"
                                                        ? "dropdown-item active"
                                                        : "dropdown-item"
                                                }
                                                onClick={() => changeLanguage("en", "English")}
                                                id="1"
                                            >
                                                English
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className={
                                                    selectedLang == "es"
                                                        ? "dropdown-item active"
                                                        : "dropdown-item"
                                                }
                                                onClick={() => changeLanguage("es", "Spanish")}
                                                id="2"
                                            >
                                                Spanish
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className={
                                                    selectedLang == "pt"
                                                        ? "dropdown-item active"
                                                        : "dropdown-item"
                                                }
                                                onClick={() => changeLanguage("pt", "Portuguese")}
                                                id="3"
                                            >
                                                Portuguese
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className={
                                                    selectedLang == "fr"
                                                        ? "dropdown-item active"
                                                        : "dropdown-item"
                                                }
                                                onClick={() => changeLanguage("fr", "French")}
                                                id="4"
                                            >
                                                French
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                        </div>
                    </div>

                </div>
          </div>
            <div className="main">
                <Router>
                    <Routes>
                        <Route exact path="/" element={<> <PageTitle title={'Get a free evaluation now'}/> <Home/></>}
                               render={() => setPageName("Bootstrap")}/>
                        <Route exact path="/ot" element={<><PageTitle title={'OT Security'}/> <OT/> </>}
                               onClick={() => setPageName('OT')}/>
                        <Route exact path="/cloud" element={<> <PageTitle title={'Cloud Security'}/> <Cloud/></>}/>
                        <Route exact path="/antiransomware" element={<> <PageTitle title={'AntiRansomware'}/> <AT/></>}/>
                        <Route exact path="/secops" element={<> <PageTitle title={'Secops'}/> <SO/></>}/>
                        <Route exact path="/thank" element={<Thank/>}/>
                        <Route path="*" element={<PageNotFound/>}/>
                    </Routes>
                </Router>
            </div>
            <div
                style={{
                    width: "100%",
                    height: 88,
                    paddingLeft: "11%",
                    // marginTop: "100px",
                    // top: 50,
                    //paddingRight: 876,
                    background: "#0F0E0E",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    display: "inline-flex",
                }}
            >
                <div
                    style={{
                        color: "white",
                        fontSize: 16,
                        fontFamily: "Inter",
                        fontWeight: "400",
                        lineHeight: 1,
                        wordWrap: "break-word",
                    }}
                >
                    Copyright © 2025 Fortinet, Inc. All Rights Reserved.
                </div>
            </div>
        </>


      );
}

root.render(
    <StartPage/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
