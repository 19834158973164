/* eslint-disable */

import React, { useEffect, useState } from 'react';

import { useTranslation } from "react-i18next";

import {Industry} from "./industries";
import {BsArrowRight} from "react-icons/bs";

export function Industries({type,submitted,industry_index}){
    const [industries, setIndustries] = useState([])
    const [index,setIndex]=useState(0)
    const [showAnimation, setShowAnimation] = useState(true);
    const [overText, setOverText] = useState(0);
    const [otherSelected,setOtherSelected]=useState(false)
    const [otherIndustry,setOtherIndustry]=useState('')
    const { t, i18n } = useTranslation();

    useEffect(() => {
        console.log("stop")
        var data = require('./industries.json');
        var industries='industries_1'
        if (['SO'].includes(type)){
            industries = 'industries_2'
        }
        if (['CLOUD','SO','AR'].includes(type)){
            industries = 'industries_3'
        }
        var array_industries = data[industries]

        if (!array_industries) {
            return
        }
        setIndustries(array_industries)
        if (industry_index!=6){
            setOverText(false)
        }
        setIndex(industry_index)

    },[]);





    const onClickSelect = (number, name) => {

        setIndex(number);
        if (name=='other'){
            setOtherSelected(true)
            return
        }else{
            setOtherSelected(false)
        }
        submitted(name,number)

    };
    const onIndustryChange=(event)=>{
        setOtherIndustry(event.target.value);
    }
    return (
        <div  >
            <div className="row justify-content-center">
                <div className='row justify-content-center'>

                    <div className="col-10 fs-3">
                        {t('content8')}  <span className="red">{t('content9')} </span>
                    </div>

                </div>
                <div className="row justify-content-center bg-gray-2" >
                    <div className='col-lg-10 col-12'>
                       <div className={'row'}>
                           {
                               industries.map((element, i) => {
                                   i=i+1
                                   return (
                                       <div key={i} className={'col-md-4 col-12'}

                                       onClick={(e)=>onClickSelect(i,element['value'])}>
                                           <div className={"col industryBox "+(index===i ? "selected":"")} >

                                               <div

                                                   className="industryBoxText"
                                               >{t(element['name'])}

                                               </div>
                                               <div className="industryBoxImage">
                                                       <img
                                                           src={element['icon_2']}
                                                           className="industryImage"
                                                       />

                                               </div>
                                           </div>
                                       </div>
                                   )
                               })
                           }
                       </div>
                    </div>
                    {otherSelected ?
                        <div className={'row justify-content-center'}>
                            <div className={'col-lg-3 col-12'}>
                                <div className={'row justify-content-center'}>
                                    <div className="col-lg-10 col-12">
                                        <div className="mb-5">
                                            <div className="row align-items-center">
                                                <label className="other">{t("indusrty_name")}</label>
                                                <div className="col-md-6 col-12">

                                                    <input type="text" className="" id="other_industry"
                                                           style={{backgroundColor: "#fff"}}
                                                           name="other_industry" value={otherIndustry}
                                                           onChange={(e) => onIndustryChange(e)}
                                                    ></input>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <button className={'btn bg-red white'}
                                                            style={{width:"100%"}}
                                                            disabled={otherIndustry === '' ? true : false}
                                                            onClick={(e)=>onClickSelect(6,otherIndustry)}>

                                                        {t("continue")} &nbsp;<BsArrowRight color="white" />
                                                    </button>
                                                </div>
                                            </div>


                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        : null

                    }
                </div>
            </div>
        </div>
    )

}