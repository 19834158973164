/* eslint-disable */

import modalImage from '../../assets/images/modal_3.png';
import playImg from "../../assets/images/play button.svg";
import {useTranslation, withTranslation} from "react-i18next";
import close from "../../assets/images/x.svg";
import React, {useEffect, useState} from "react";
import axios from "axios";
import data from "bootstrap/js/src/dom/data";
export function ModalEmail2({childtrigger,closeTrigger,vertical}) {
    const [companyEmail,setCompanyEmail]=useState(null)
    const [industry,setIndustry] = useState(null)
    const [show,setShow]=useState(false)
    const [formData,setFormData]=useState({})
    const [questions,setQuestions]=useState({})
    const [score,setScore]=useState(0)
    const { t, i18n } = useTranslation();

    useEffect(() => {
        childtrigger.current = showModal
    }, [childtrigger.current]);
    const sendData=()=>{
        //let rouded =Math.round(score)
        console.log(companyEmail)
        console.log(formData)
        console.log(questions)
        console.log(industry)
        if(!validateEmail(companyEmail)){
            document.getElementById("email_message").className =
                "LV_validation_message LV_invalid";
            document.getElementById("email_message_span").innerHTML =
                "Please use your corporate e-mail";
            return
        }
        if(vertical=='AR'){
            vertical='antiransomware'
        }
        if(vertical=='CLOUD'){
            vertical='cloud'
        }
        let obj = {
            first_name: formData.firstname,
            last_name: formData.lastname,
            company: formData.company,
            job_title: formData.jobtitle,
            phone: formData.international_code+formData.phone,
            country: formData.country,
            industry: industry ,
            maturity_level: score,
            vertical: vertical,
            email: companyEmail,
            avg: score,
            language:i18n.language
        };
        console.log(obj)
        sendEloqua()
        sendDataBackEnd(obj)
        closeModal()
    }
    const sendEloqua=()=>
    {
        console.log(vertical)
        let EloquaForm= '2025_OTMaturityLevel'
        if(vertical=='antiransomware'){
            EloquaForm='2025_AntiransomwareMaturityLevel'
        }
        if(vertical=='cloud'){
            EloquaForm='2025_CloudSecurityMaturityLevel'

        }
        if(vertical=='SO')
        {
            EloquaForm='2025_SecOpsMaturityLevel'
        }

        var formData_eloqua = new FormData();
        formData_eloqua.append("elqFormName", EloquaForm);
        formData_eloqua.append("elqSiteId", "3049749");
        formData_eloqua.append("elqCampaignId", "");
        formData_eloqua.append("EmailAddress", companyEmail);
        formData_eloqua.append("country", formData.country);
        formData_eloqua.append("firstName", formData.firstname);
        formData_eloqua.append("lastName", formData.lastname);
        formData_eloqua.append("company", formData.company);
        formData_eloqua.append("title", formData.jobtitle);
        formData_eloqua.append("optinlang", i18n.language);
        formData_eloqua.append("Phone", formData.international_code+formData.phone);
        formData_eloqua.append("Q0", industry );
        formData_eloqua.append("Q1", parseInt(questions[0]));
        formData_eloqua.append("Q2", parseInt(questions[1]));
        formData_eloqua.append("Q3", parseInt(questions[2]));
        formData_eloqua.append("Q4", parseInt(questions[3]));
        formData_eloqua.append("Q5", parseInt(questions[4]));
        formData_eloqua.append("Q6", parseInt(questions[5]));

        formData_eloqua.append("Q7", parseInt(questions[6]));
        formData_eloqua.append("Q8", parseInt(questions[7]));
        formData_eloqua.append("Q9", parseInt(questions[8]));
        formData_eloqua.append("Q10", parseInt(questions[9]));
        formData_eloqua.append("Q11", "avg score " + score);
        axios.post("https://s3049749.t.eloqua.com/e/f2", formData_eloqua).then(
            (response) => { },
            (error) => {

            }
        );
    }
    const sendDataBackEnd=(data)=>{
        var apiurl = process.env.REACT_APP_API_URL
        axios.post(apiurl + "savedInfo", data, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST",
                "Content-Type": "application/json",
            },
        }) .then((response)=>{
            console.log(response)
        })
    }
    const validateEmail = (email) => {
        if (
            /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/.test(
                email
            )
        ) {
            return true;
        }
        return false;
    };
    const onEmailChange=(event)=>{
        setCompanyEmail(event.target.value)

        if (
            event.target.value.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
            //setEnableConfirm(true);
        } else {
            //setEnableConfirm(false);
        }
    }
    const closeModal=()=>{
        setShow(false)
        closeTrigger()
    }
    const showModal=(inputs,score_questions,questions,industry)=>{
        console.log(industry)
        setFormData(inputs)
        setQuestions(questions)
        setScore(score_questions)
        setIndustry(industry)
        setShow(true)
    }
    return (
        <div
            className={`modal fade ${show?'show':'hide'}`}
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            style={{display:show?'block':''}}
        >
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                    <div className="modal-body paddingImage">
                        <div className="container-fluid paddingImage">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 order-lg-last">
                                    <div className="modal-email-image"

                                    >
                                        <img
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                background:
                                                    "linear-gradient(0deg, #D9D9D9 0%, #D9D9D9 100%)",
                                                borderTopRightRadius: 300,
                                                objectFit: "cover",
                                            }}
                                            src={modalImage}
                                        />
                                        <div
                                            style={{
                                                width: 40,
                                                height: 40,
                                                padding: 2,
                                                right: 3,
                                                position: "absolute",
                                                background: "white",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "inline-flex",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => sendData()}
                                        >
                                            <div
                                                style={{
                                                    width: 36,
                                                    height: 36,
                                                    position: "relative",
                                                    flexDirection: "column",
                                                    justifyContent: "flex-start",
                                                    alignItems: "flex-start",
                                                    display: "flex",
                                                }}
                                            >
                                                <img src={close} style={{width: 36, height: 36}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 order-lg-first">
                                    <div
                                        style={{
                                            width: "100%",
                                            //height: 400,
                                            position: "relative",
                                        }}
                                    >
                                        <div
                                            style={{
                                                // alignSelf: "stretch",
                                                color: "#DA291C",
                                                fontSize: 20,
                                                fontFamily: "Inter",
                                                fontWeight: "700",
                                                textTransform: "uppercase",
                                                wordWrap: "break-word",
                                                marginTop: 20,
                                                textAlign: "left",
                                                paddingLeft: "5px",
                                                fontStyle: "normal",
                                            }}
                                        >

                                            {t("popup_content1")}

                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: 30,
                                                textAlign: "left",
                                                paddingLeft: "5px",
                                            }}
                                        >
                          <span
                              style={{
                                  color: "#0F0E0E",
                                  fontSize: 36,
                                  fontFamily: "Inter",
                                  fontWeight: "500",
                                  textAlign: "left",
                                  lineHeight: 1,
                                  wordWrap: "break-word",
                                  fontStyle: "normal",
                              }}
                          >
                            {/* Remember that this report can also be personalized
                                in a{" "} */}
                              {t("popup_content2")}{" "}
                          </span>
                                            <span
                                                style={{
                                                    color: "#DA291C",
                                                    fontSize: 36,
                                                    fontFamily: "Inter",
                                                    fontWeight: "500",
                                                    textAlign: "left",
                                                    wordWrap: "break-word",
                                                    lineHeight: 1,
                                                }}
                                            >
                            {/* virtual session at no cost */}
                                                {t("popup_content3")}
                          </span>
                                            <span
                                                style={{
                                                    color: "#0F0E0E",
                                                    fontSize: 36,
                                                    fontFamily: "Inter",
                                                    fontWeight: "500",
                                                    textAlign: "left",
                                                    lineHeight: 1,
                                                    wordWrap: "break-word",
                                                }}
                                            >
                            {" "}
                                                {/* with a Fortinet expert */}
                                                {t("popup_content4")}
                          </span>
                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                                color: "#0F0E0E",
                                                fontSize: 20,
                                                fontFamily: "Inter",
                                                fontWeight: "400",
                                                textAlign: "left",
                                                wordWrap: "break-word",
                                                marginTop: 20,
                                                paddingLeft: "5px",
                                                fontStyle: "normal",
                                            }}
                                        >

                                            {t("popup_content5")}
                                        </div>
                                        <div
                                            style={{
                                                width: "95%",
                                                height: "13%",
                                                paddingLeft: 24,
                                                paddingRight: 24,
                                                paddingTop: 12,
                                                paddingBottom: 12,
                                                background: "#F3F3F3",
                                                flexDirection: "column",
                                                justifyContent: "flex-start",
                                                // alignItems: "flex-start",
                                                gap: 12,
                                                display: "inline-flex",
                                                marginTop: 20,
                                                marginLeft: 5,
                                                marginRight: 5,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                    gap: 8,
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <input
                                                    style={{
                                                        color: "#0F0E0E",
                                                        fontSize: 16,
                                                        fontFamily: "Inter",
                                                        fontWeight: "400",
                                                        lineHeight: 1,
                                                        background: "#F3F3F3",
                                                        wordWrap: "break-word",
                                                        borderColor: "#F3F3F3",
                                                        paddingLeft: 5,
                                                        width: "100%",
                                                        height: "13%",
                                                        border: "none",
                                                    }}
                                                    type="email"
                                                    value={companyEmail}
                                                    onChange={(e) => onEmailChange(e)}
                                                    placeholder={t("popup_content6")}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            id="email_message"
                                            className="LV_validation_message LV_valid"
                                        >
                                            <span id="email_message_span"></span>
                                        </div>


                                        <div
                                            className="confirmButton"
                                            style={{
                                                marginTop: 20,
                                                marginLeft: 5,
                                                marginRight: 5,

                                            }}
                                            onClick={() => sendData()}
                                        >
                                            <div
                                                style={{
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                    gap: 8,
                                                    display: "inline-flex",
                                                }}
                                            >


                                                <div
                                                    style={{
                                                        color: "white",
                                                        fontSize: 16,
                                                        fontFamily: "Inter",
                                                        fontWeight: "600",
                                                        lineHeight: 1,
                                                        wordWrap: "break-word",
                                                        fontStyle: "normal",
                                                    }}
                                                >
                                                    {/* Confirm */}
                                                    {t("popup_content7")}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
